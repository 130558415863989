import { useForm } from "react-hook-form";
import { z } from "zod";

/* Hooks */
import { useUserState } from "@/hooks/user-state";
import { zodResolver } from "@hookform/resolvers/zod";

/* Libs */
import { cn } from "@/lib/utils";

/* Components */
import { loginFormSchema } from "@/components/forms/schema/LoginFormSchema";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

export const LoginForm = () => {
	const { isUserLoading, userError, login } = useUserState();
	const form = useForm<z.infer<typeof loginFormSchema>>({
		resolver : zodResolver(loginFormSchema),
		defaultValues : {
			username : "",
			password : "",
		}
	});

	const onSubmit = ( values : z.infer<typeof loginFormSchema> ) => {
		login(values, form);
	};

	return (
		<Form {...form}>
			<form className="w-full max-w-lg mx-auto space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
				<h2 className="text-center text-2xl mb-9">Přihlášení</h2>

				{userError && (
					<Alert variant="destructive">
						<AlertTitle>Chyba</AlertTitle>
						<AlertDescription>
							{userError}
						</AlertDescription>
					</Alert>
				)}

				<FormField
					name="username"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>Uživatelské jméno</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="Uživatelské jméno"
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<FormField
					name="password"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>Heslo</FormLabel>
							<FormControl>
								<Input
									{...field}
									type="password"
									placeholder="Heslo"
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<Button className={cn("ml-auto block", isUserLoading ? "animate-pulse pointer-events-none" : "")}>
					{isUserLoading ? "Přihlašování..." : "Přihlásit se"}
				</Button>
			</form>
		</Form>
	);
};