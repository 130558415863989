import { useForm } from "react-hook-form";
import { z } from "zod";

/* Libs */
import { cn } from "@/lib/utils";

/* Hooks */
import { zodResolver } from "@hookform/resolvers/zod";
import { useCouponState } from "@/hooks/coupon-state";

/* Components */
import { couponFormSchema } from "@/components/forms/schema/CouponFormSchema";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useUserState } from "@/hooks/user-state";

export const CouponVerificationForm = () => {
	const { user } = useUserState();
	const {
		couponMessage,
		isCouponValid,
		isCouponBlocked,
		isCouponLoading,
		couponInfo,
		hasBlockCouponError,
		validateCoupon,
		resetForm,
		blockCouponCode
	} = useCouponState();

	const form = useForm<z.infer<typeof couponFormSchema>>({
		resolver : zodResolver(couponFormSchema),
		defaultValues : {
			couponCode : "",
		}
	});

	if(!user) return null;

	const onSubmit = () => {
		validateCoupon(form, user?.partner, user?.service_provider);
	};

	return (
		<div className="w-full max-w-lg mx-auto space-y-4">
			<h2 className="text-center text-2xl mb-9">Ověření kupónu</h2>

			{isCouponBlocked && (
				<>
					<Alert variant="success">
						<AlertTitle>
							Kód byl uplatněn!
						</AlertTitle>
						{couponMessage && (
							<AlertDescription className="text-slate-700">
								{couponMessage}
							</AlertDescription>
						)}
					</Alert>
					<div className="flex gap-4 justify-end">
						<Button variant="ghost" onClick={() => resetForm(form)}>Ověřit jiný kód</Button>
					</div>
				</>
			)}

			{hasBlockCouponError && (
				<Alert variant="destructive">
					<AlertTitle>Nastala chyba.</AlertTitle>
					{couponMessage && (
						<AlertDescription className="text-slate-700">
							{couponMessage}
						</AlertDescription>
					)}
				</Alert>
			)}

			{!isCouponBlocked && isCouponValid && (
				<>
					<Alert variant="success">
						<AlertTitle>
							Kód je platný!
						</AlertTitle>
						{couponMessage && (
							<AlertDescription className="text-slate-700 mb-4">
								{couponMessage}
							</AlertDescription>
						)}
						<ul className="text-sm text-slate-700">
							<li><strong>{couponInfo}</strong></li>
						</ul>
					</Alert>
					<div className="flex gap-4 justify-end">
						<Button variant="ghost" onClick={() => resetForm(form)}>Ověřit jiný kód</Button>
						<Button variant="destructive" onClick={() => blockCouponCode(user?.partner)}>Uplatnit kód</Button>
					</div>
				</>
			)}

			<Form {...form}>
				<form
					className={cn("w-full max-w-lg mx-auto space-y-4", isCouponLoading ? "opacity-75 pointer-events-none" : "")}
					onSubmit={form.handleSubmit(onSubmit)}
				>

					{!isCouponBlocked && !isCouponValid && (
						<>
							<FormField
								name="couponCode"
								render={( { field } ) => (
									<FormItem>
										<FormLabel>Kód kupónu</FormLabel>
										<FormControl>
											<Input
												{...field}
												placeholder="Zadejte kód kupónu"
											/>
										</FormControl>
										<FormMessage className="text-xs" />
									</FormItem>
								)}
							/>
							<Button className={cn("ml-auto block", isCouponLoading ? "animate-pulse" : "")}>
								{isCouponLoading ? "Ověřuji platnost..." : "Ověřit platnost"}
							</Button>
						</>
					)}
				</form>
			</Form>
		</div>
	);
};