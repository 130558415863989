import { create } from "zustand";
import axios from "axios";
import { z } from "zod";

/* Constants */
import { USER_ROLES } from "@/constants";

/* Types */
import { User } from "@/types";

/* Schemas */
import { userFormSchema, userFormSchemaUpdate } from "@/components/forms/schema/UserFormSchema";
import { loginFormSchema } from "@/components/forms/schema/LoginFormSchema";

let defaultData;
if ( process.env.NODE_ENV === "production" ) {
	defaultData = {
		isLoggedIn : false,
		role : USER_ROLES.GUEST,
	};
} else {
	defaultData = {
		isLoggedIn : true,
		role : USER_ROLES.ADMIN,
	};
}
const { isLoggedIn, role } = window.appConfig ?? defaultData;

interface useUserStateStore {
	userToast : any;
	userError : string | null;
	isLoggedIn : boolean;
	username : string;
	role : string;
	isUserLoading : boolean;
	users : User[];
	user : User | null;
	partner : User | null;
	userId : number | null;
	isPartnerLoading : boolean;
	isDeleteUserAlertOpen : boolean;
	isAddUserModalOpen : boolean;
	isUpdateUserModalOpen : boolean;
	setUserToast : any;
	fetchActiveUsername : () => void;
	logout : () => void;
	login : ( values : z.infer<typeof loginFormSchema>, form : any ) => void;
	setUserError : ( value : string | null ) => void;
	setIsPartnerLoading : ( isPartnerLoading : boolean ) => void;
	setIsUpdateUserModalOpen : ( value : boolean ) => void;
	setIsAddUserModalOpen : ( value : boolean ) => void;
	setIsDeleteUserAlertOpen : ( value : boolean ) => void;
	setIsUserLoading : ( isUserLoading : boolean ) => void;
	setIsLoggedIn : ( isLoggedIn : boolean ) => void;
	setUserId : ( deleteUserId : number | null ) => void;
	setRole : ( role : string ) => void;
	fetchUsers : () => void;
	fetchUser : () => void;
	deleteUser : () => void;
	updateUser : ( values : z.infer<typeof userFormSchemaUpdate>, form : any ) => void;
	addUser : ( values : z.infer<typeof userFormSchema>, form : any ) => void;
	fetchPartner : ( id : number ) => void;
}

export const useUserState = create<useUserStateStore>(( set ) => ( {
	userToast : null,
	userError : null,
	isLoggedIn : isLoggedIn,
	username : "",
	role : role,
	users : [],
	user : null,
	partner : null,
	isUserLoading : false,
	userId : null,
	isPartnerLoading : false,
	isDeleteUserAlertOpen : false,
	isAddUserModalOpen : false,
	isUpdateUserModalOpen : false,
	setUserError : ( value : string | null ) => set(() => ( { userError : value } )),
	setUserToast : ( value : object ) => set(() => ( { userToast : value } )),
	setIsUpdateUserModalOpen : ( value : boolean ) => {
		set(() => ( { isUpdateUserModalOpen : value } ));
		set(() => ( { user : null } ));

		if ( value ) {
			useUserState.getState().fetchUser();
		}
	},
	setIsPartnerLoading : ( isPartnerLoading : boolean ) => set(() => ( { isPartnerLoading } )),
	setIsAddUserModalOpen : ( value : boolean ) => set(() => ( { isAddUserModalOpen : value } )),
	setIsDeleteUserAlertOpen : ( value : boolean ) => set(() => ( { isDeleteUserAlertOpen : value } )),
	setIsUserLoading : ( isUserLoading : boolean ) => set(() => ( { isUserLoading } )),
	setIsLoggedIn : ( isLoggedIn : boolean ) => set(() => ( { isLoggedIn } )),
	setRole : ( role : string ) => set(() => ( { role } )),
	setUserId : ( userId : number | null ) => set(() => ( { userId } )),
	fetchActiveUsername : () => {
		axios.get("user/active").then(( response : any ) => {
			if ( response.data.success ) {
				set(() => ( {
					username : response.data.user.username,
					user: response.data.user
				} ));
			}
		}).catch(( error ) => {
			console.error(error);
		});
	},
	logout : () => {
		axios.post("user/logout").then(( response ) => {
			if ( response.data.success ) {
				set(() => ( { isLoggedIn : false } ));
				set(() => ( { role : USER_ROLES.GUEST } ));
				useUserState.getState().userToast({ description : "Odhlášení proběhlo úspěšně.", variant : "success" });
			}
		}).catch(( error ) => {
			console.error(error);
		});
	},
	login : ( values : z.infer<typeof loginFormSchema>, form : any ) => {
		set(() => ( { isUserLoading : true, userError : null } ));
		axios.post("user/login", values).then(( response : any ) => {
			set(() => ( { isUserLoading : false } ));
			if ( response.data.success ) {
				set(() => ( { isLoggedIn : true } ));
				set(() => ( { role : response.data.role } ));
				set(() => ( { username : response.data.username } ));
				form.clearErrors();
				useUserState.getState().fetchActiveUsername();
				useUserState.getState().userToast({ description : "Přihlášení proběhlo úspěšně.", variant : "success" });
			} else {
				set(() => ( { userError : response.data.message } ));
			}
		}).catch(( error ) => {
			set(() => ( { isUserLoading : false } ));
			console.error(error);
		});
	},
	fetchUsers : () => {
		set(() => ( { isUserLoading : true } ));
		axios.get("user/list").then(( response : any ) => {
			set(() => ( { isUserLoading : false } ));
			if ( response.data.success ) {
				set(() => ( { users : response.data.users } ));
			}
		}).catch(( error ) => {
			console.error(error);
			set(() => ( { isUserLoading : false } ));
		});
	},
	fetchUser : () => {
		axios.post(`user`, { id : useUserState.getState().userId }).then(( response : any ) => {
			set(() => ( { user : null } ));
			if ( response.data.success ) {
				set(() => ( { user : response.data.user } ));
			}
		}).catch(( error ) => {
			console.error(error);
		});
	},
	deleteUser : () => {
		set(() => ( { isDeleteUserAlertOpen : false } ));
		set(() => ( { isUserLoading : true } ));
		axios.post("user/delete", { id : useUserState.getState().userId }).then(( response : any ) => {
			set(() => ( { isUserLoading : false } ));
			set(() => ( { userId : null } ));
			if ( response.data.success ) {
				useUserState.getState().fetchUsers();
				useUserState.getState().fetchActiveUsername();
				useUserState.getState().userToast({ description : "Uživatel byl smazán.", variant : "success" });
			}
		}).catch(( error ) => {
			console.error(error);
			set(() => ( { userId : null } ));
			set(() => ( { isUserLoading : false } ));
			useUserState.getState().userToast({ description : "Uživatele se nepodařilo smazat.", variant : "destructive" });
		});
	},
	addUser : ( values : z.infer<typeof userFormSchema>, form : any ) => {
		set(() => ( { isUserLoading : true } ));
		axios.post("user/add", values).then(( response : any ) => {
			set(() => ( { isUserLoading : false } ));
			if ( response.data.success ) {
				form.clearErrors();
				useUserState.getState().fetchUsers();
				useUserState.getState().fetchActiveUsername();
				useUserState.getState().setIsAddUserModalOpen(false);
				useUserState.getState().userToast({ description : "Uživatel byl vytvořen.", variant : "success" });
			} else {
				form.setError(response.data.field, {
					message : response.data.message
				});
				useUserState.getState().userToast({ description : "Uživatele se nepodařilo vytvořit.", variant : "destructive" });
			}
		}).catch(( error ) => {
			console.error(error);
			set(() => ( { isUserLoading : false } ));
			useUserState.getState().userToast({ description : "Uživatele se nepodařilo vytvořit.", variant : "destructive" });
		});
	},
	updateUser : ( values : z.infer<typeof userFormSchemaUpdate>, form : any ) => {
		set(() => ( { isUserLoading : true } ));
		axios.post("user/update", values).then(( response : any ) => {
			set(() => ( { isUserLoading : false } ));
			if ( response.data.success ) {
				form.clearErrors();
				useUserState.getState().fetchUsers();
				useUserState.getState().fetchActiveUsername();
				useUserState.getState().setIsUpdateUserModalOpen(false);
				useUserState.getState().userToast({ description : "Uživatel byl upraven.", variant : "success" });
			} else {
				form.setError(response.data.field, {
					message : response.data.message
				});
				useUserState.getState().userToast({ description : "Uživatele se nepodařilo upravit.", variant : "destructive" });
			}
		}).catch(( error ) => {
			console.error(error);
			set(() => ( { isUserLoading : false } ));
			useUserState.getState().userToast({ description : "Uživatele se nepodařilo upravit.", variant : "destructive" });
		});
	},
	fetchPartner : ( id ) => {
		set(() => ( { isPartnerLoading : true, partner : null } ));
		axios.post(`user`, { id }).then(( response : any ) => {
			if ( response.data.success ) {
				set(() => ( { partner : response.data.user, isPartnerLoading : false } ));
			} else {
				set(() => ( { partner : null, isPartnerLoading : false } ));
			}
		}).catch(( error ) => {
			set(() => ( { isPartnerLoading : true, partner : null } ));
			console.error(error);
		});
	}
} ));
