import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const buttonVariants = cva(
	"inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-0 disabled:pointer-events-none disabled:opacity-50",
	{
		variants : {
			variant : {
				default : "bg-primary text-primary-foreground hover:bg-primary/70",
				destructive :
					"bg-destructive text-destructive-foreground hover:bg-destructive/70",
				outline :
					"border border-input bg-background hover:bg-accent hover:text-accent-foreground",
				secondary :
					"bg-secondary text-secondary-foreground hover:bg-secondary/70",
				ghost : "hover:bg-accent hover:text-accent-foreground",
				link : "text-primary underline-offset-4 hover:underline",
			},
			size : {
				default : "h-10 px-4 py-2",
				sm : "h-9 rounded-md px-3",
				lg : "h-11 rounded-md px-8",
				icon : "h-10 w-10",
			},
		},
		defaultVariants : {
			variant : "default",
			size : "default",
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild? : boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	( { className, variant, size, asChild = false, ...props }, ref ) => {
		const Comp = asChild ? Slot : "button";
		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...props}
			/>
		);
	}
);
Button.displayName = "Button";

export { Button, buttonVariants };
