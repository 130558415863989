import { z } from "zod";

export const userFormSchema = z.object({
	partner : z
		.string()
		.min(1, { message : "Partner je povinný." }),
	username : z
		.string()
		.min(1, { message : "Uživatelské jméno je povinné." }),
	password : z
		.string()
		.min(1, { message : "Heslo je povinné." }),
	email : z
		.string()
		.min(1, { message : "E-mail je povinný." })
		.email({ message : "E-mail není ve správném formátu." }),
	role : z
		.string()
		.min(1, { message : "Oprávnění je povinné." }),
	service_provider : z
		.any()
		.refine(( value ) => value != "" && value != 0, { message : "Poskytovatel služeb je povinný." })
});

export const userFormSchemaUpdate = z.object({
	id : z
		.number()
		.nullable(),
	partner : z
		.string()
		.min(1, { message : "Partner je povinný." }),
	username : z
		.string()
		.min(1, { message : "Uživatelské jméno je povinné." }),
	password : z
		.string(),
	email : z
		.string()
		.min(1, { message : "E-mail je povinný." })
		.email({ message : "E-mail není ve správném formátu." }),
	role : z
		.string()
		.min(1, { message : "Oprávnění je povinné." }),
	service_provider : z
		.any()
		.refine(( value ) => value != "" && value != 0, { message : "Poskytovatel služeb je povinný." })
});