import React, { useEffect } from "react";

/* Hooks */
import { useUserState } from "@/hooks/user-state";

/* Forms */
import { AddUserForm } from "@/components/forms/add-user/AddUserForm";
import { UpdateUserForm } from "@/components/forms/update-user/UpdateUserForm";

/* Components */
import { UserTable } from "@/components/UserTable";
import { DialogOverlay } from "@radix-ui/react-dialog";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const UserManagementPage = () => {
	const {
		isDeleteUserAlertOpen,
		isAddUserModalOpen,
		isUpdateUserModalOpen,
		fetchUsers,
		deleteUser,
		setIsDeleteUserAlertOpen,
		setIsAddUserModalOpen,
		setIsUpdateUserModalOpen
	} = useUserState();

	useEffect(() => {
		document.title = "Uživatelé | JednotaCB";
		fetchUsers();
	}, []);

	return (
		<>
			<h2 className="text-center text-2xl mb-9">Správa uživatelů</h2>

			<UserTable />

			<Dialog onOpenChange={() => setIsDeleteUserAlertOpen(!isDeleteUserAlertOpen)} open={isDeleteUserAlertOpen}>
				<DialogOverlay>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>Opravdu si přejete smazat tohoto uživatele?</DialogTitle>
							<DialogDescription>Tato akce je nevratná a&nbsp;uživatel bude smazán ze systému.</DialogDescription>
						</DialogHeader>
						<DialogFooter className="flex gap-3">
							<Button variant="ghost" onClick={() => setIsDeleteUserAlertOpen(false)}>Zrušit</Button>
							<Button variant="destructive" onClick={() => deleteUser()}>Smazat</Button>
						</DialogFooter>
					</DialogContent>
				</DialogOverlay>
			</Dialog>

			<Dialog onOpenChange={() => setIsAddUserModalOpen(!isAddUserModalOpen)} open={isAddUserModalOpen}>
				<DialogOverlay>
					<DialogContent>
						<AddUserForm />
					</DialogContent>
				</DialogOverlay>
			</Dialog>

			<Dialog onOpenChange={() => setIsUpdateUserModalOpen(!isUpdateUserModalOpen)} open={isUpdateUserModalOpen}>
				<DialogOverlay>
					<DialogContent>
						<UpdateUserForm />
					</DialogContent>
				</DialogOverlay>
			</Dialog>
		</>
	);
};

export default UserManagementPage;