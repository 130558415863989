import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

/* Hooks */
import { zodResolver } from "@hookform/resolvers/zod";
import { useUserState } from "@/hooks/user-state";

/* Libs */
import { cn } from "@/lib/utils";

/* Components */
import { userFormSchemaUpdate } from "@/components/forms/schema/UserFormSchema";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import { USER_ROLES } from "@/constants";

export const UpdateUserForm = () => {
	const { isUserLoading, user, setIsUpdateUserModalOpen, updateUser } = useUserState();
	const form = useForm<z.infer<typeof userFormSchemaUpdate>>({
		resolver : zodResolver(userFormSchemaUpdate),
		defaultValues : {
			id : null,
			partner : "",
			username : "",
			password : "",
			email : "",
			role : "user",
			service_provider : "",
		}
	});

	const onSubmit = ( values : z.infer<typeof userFormSchemaUpdate> ) => {
		updateUser(values, form);
	};

	useEffect(() => {
		if ( user ) {
			form.setValue("id", user.id);
			form.setValue("partner", user.partner);
			form.setValue("username", user.username);
			form.setValue("email", user.email);
			form.setValue("role", user.role);
			form.setValue("service_provider", user.service_provider)
		}
	}, [ user ]);

	if ( !user ) {
		return (
			<div className="w-full max-w-lg mx-auto space-y-4">
				<h2 className="text-center text-2xl mb-9">Úprava uživatele</h2>
				<div className="space-y-2">
					<Skeleton className="h-6 w-28" />
					<Skeleton className="h-10" />
				</div>
				<div className="space-y-2">
					<Skeleton className="h-6 w-28" />
					<Skeleton className="h-10" />
				</div>
				<div className="space-y-2">
					<Skeleton className="h-6 w-28" />
					<Skeleton className="h-10" />
				</div>
				<div className="space-y-2">
					<Skeleton className="h-6 w-28" />
					<Skeleton className="h-10" />
				</div>
				<div className="space-y-2">
					<Skeleton className="h-6 w-28" />
					<Skeleton className="h-10" />
				</div>
				<div>
					<div className="w-full h-px bg-slate-200 my-6"></div>
					<div className="flex gap-4 justify-between items-center">
						<Skeleton className="h-10 w-28" />
						<Skeleton className="h-10 w-28" />
					</div>
				</div>
			</div>
		);
	}

	return (
		<Form {...form}>
			<form autoComplete="off" className="w-full max-w-lg mx-auto space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
				<h2 className="text-center text-2xl mb-9">Úprava uživatele</h2>

				<FormField
					name="id"
					render={( { field } ) => (
						<FormItem hidden>
							<FormControl>
								<Input
									{...field}
									placeholder="ID"
									hidden
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>

				<FormField
					name="partner"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>Partner</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="Partner"
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<FormField
					name="service_provider"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>Poskytovatel služeb</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="Poskytovatel služeb"
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<FormField
					name="username"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>Uživatelské jméno</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="Uživatelské jméno"
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<FormField
					name="password"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>Nové heslo</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="Nové heslo"
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<FormField
					name="email"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>E-mail</FormLabel>
							<FormControl>
								<Input
									{...field}
									placeholder="E-mail"
								/>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<FormField
					name="role"
					render={( { field } ) => (
						<FormItem>
							<FormLabel>Oprávnění uživatele</FormLabel>
							<FormControl>
								<Select onValueChange={field.onChange} defaultValue={user?.role}>
									<SelectTrigger>
										<SelectValue placeholder="Vyberte oprávění" />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value={USER_ROLES.PARTNER}>{USER_ROLES.PARTNER}</SelectItem>
										<SelectItem value={USER_ROLES.ADMIN}>{USER_ROLES.ADMIN}</SelectItem>
									</SelectContent>
								</Select>
							</FormControl>
							<FormMessage className="text-xs" />
						</FormItem>
					)}
				/>
				<div>
					<div className="w-full h-px bg-slate-200 my-6"></div>
					<div className="flex gap-4 justify-end items-center">
						<Button variant="ghost" onClick={() => setIsUpdateUserModalOpen(false)}>Zrušit</Button>
						<Button className={cn("ml-auto block", isUserLoading ? "animate-pulse pointer-events-none" : "")}>
							{isUserLoading ? "Upravuji uživatele..." : "Upravit uživatele"}
						</Button>
					</div>
				</div>
			</form>
		</Form>
	);
};