import React, { useEffect } from "react";

/* Types */
import { User } from "@/types";

/* Constants */
import { USER_ROLES } from "@/constants";

/* Libs */
import { cn } from "@/lib/utils";

/* Icons */
import { ArrowUpDown, ChevronDown, TrashIcon, PencilIcon, EyeIcon } from "lucide-react";

/* Hooks */
import { useUserState } from "@/hooks/user-state";

/* Utils */
import { formatNumber } from "@/utils/formatNumber";

/* Components */
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, } from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "@/components/ui/table";
import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "@/components/ui/tooltip";
import { Link } from "react-router-dom";

export const UserTable = () => {
	const { users, isUserLoading, setUserId, setIsDeleteUserAlertOpen, setIsAddUserModalOpen, setIsUpdateUserModalOpen } = useUserState();
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const [ columnFilters, setColumnFilters ] = React.useState<ColumnFiltersState>(
		[]
	);
	const [ columnVisibility, setColumnVisibility ] =
		React.useState<VisibilityState>({});
	const [ rowSelection, setRowSelection ] = React.useState({});
	const columns : ColumnDef<User>[] = [
		{
			accessorKey : "partner",
			header : ( { column } ) => {
				return (
					<Button
						variant="ghost"
						className="-ml-4"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						Partner
						<ArrowUpDown className="ml-2 h-4 w-4" />
					</Button>
				);
			},
			cell : ( { row } ) => <div>{row.getValue("partner")}</div>,
		},
		{
			accessorKey : "username",
			header : "Uživatelské jméno",
			cell : ( { row } ) => <div>{row.getValue("username")}</div>,
		},
		{
			accessorKey : "email",
			header : "E-mail",
			cell : ( { row } ) => <div>{row.getValue("email")}</div>,
		},
		{
			accessorKey : "validated_codes",
			header : "Ověřené kódy",
			cell : ( { row } ) => <div>{formatNumber(row.getValue("validated_codes"))}</div>,
		},
		{
			accessorKey : "blocked_codes",
			header : "Blokované kódy",
			cell : ( { row } ) => <div>{formatNumber(row.getValue("blocked_codes"))}</div>,
		},
		{
			accessorKey : "role",
			header : ( { column } ) => {
				return (
					<Button
						variant="ghost"
						className="-ml-4"
						onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
					>
						Oprávnění
						<ArrowUpDown className="ml-2 h-4 w-4" />
					</Button>
				);
			},
			cell : ( { row } ) => <Badge
				variant={row.getValue("role") === USER_ROLES.ADMIN ? "destructive" : "default"}>{row.getValue("role")}</Badge>,
		},
		{
			accessorKey : "id",
			header : "",
			cell : ( { row } ) => <div>
				<div className="flex justify-end gap-3">
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									size="icon"
									className="w-8 h-8 bg-blue-400 hover:bg-blue-300"
									asChild
								>
									<Link to={`/uzivatel/${row.getValue("id")}`}>
										<EyeIcon className="w-4 h-4" />
									</Link>
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								Detail uživatele
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									size="icon"
									className="w-8 h-8"
									onClick={() => {
										setUserId(row.getValue("id"));
										setIsUpdateUserModalOpen(true);
									}}
								>
									<PencilIcon className="w-4 h-4" />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								Upravit uživatele
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="destructive"
									size="icon"
									className="w-8 h-8"
									onClick={() => {
										setUserId(row.getValue("id"));
										setIsDeleteUserAlertOpen(true);
									}}
								>
									<TrashIcon className="w-4 h-4" />
								</Button>
							</TooltipTrigger>
							<TooltipContent>
								Smazat uživatele
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				</div>
			</div>,
		}
	];

	const table = useReactTable({
		data : users,
		columns,
		onSortingChange : setSorting,
		onColumnFiltersChange : setColumnFilters,
		onColumnVisibilityChange : setColumnVisibility,
		onRowSelectionChange : setRowSelection,
		getCoreRowModel : getCoreRowModel(),
		getPaginationRowModel : getPaginationRowModel(),
		getSortedRowModel : getSortedRowModel(),
		getFilteredRowModel : getFilteredRowModel(),
		state : {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
	});

	const storeColumnsVisibility = ( column : any, value : boolean ) => {
		const columnsVisibility = JSON.parse(localStorage.getItem("columnsVisibility") ?? "{}");
		columnsVisibility[column.columnDef?.accessorKey] = value;
		localStorage.setItem("columnsVisibility", JSON.stringify(columnsVisibility));
	};

	useEffect(() => {
		const columnsVisibility = JSON.parse(localStorage.getItem("columnsVisibility") ?? "{}");
		setColumnVisibility(columnsVisibility);
		Object.keys(columnsVisibility).forEach(( key ) => {
			const column = table.getColumn(key);
			if ( column ) {
				column.toggleVisibility(columnsVisibility[key]);
			}
		});
	}, []);

	return (
		<div className={cn("w-full", isUserLoading ? "opacity-50" : "")}>
			<div className="flex flex-col md:flex-row items-center justify-between gap-4 py-4">
				<Input
					placeholder="Uživatelské jméno..."
					value={( table.getColumn("username")?.getFilterValue() as string ) ?? ""}
					onChange={( event ) =>
						table.getColumn("username")?.setFilterValue(event.target.value)
					}
					className="md:max-w-sm order-2 md:order-1"
				/>
				<div className="flex justify-between md:justify-end w-full gap-4 items-center order-1 md:order-2">
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button className="inline-flex items-center gap-2" variant="outline">
								Sloupce <ChevronDown className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							{table
								.getAllColumns()
								.filter(( column ) => column.getCanHide())
								.map(( column ) => {
									if ( column.columnDef.header !== "" ) {
										return (
											<DropdownMenuCheckboxItem
												key={column.id}
												className="capitalize"
												checked={column.getIsVisible()}
												onCheckedChange={( value ) => {
													storeColumnsVisibility(column, value);
													column.toggleVisibility(value);
												}}
											>
												{/*@ts-ignore*/}
												{typeof column?.columnDef?.header !== "function" ? column?.columnDef?.header : column.id}
											</DropdownMenuCheckboxItem>
										);
									}
								})}
						</DropdownMenuContent>
					</DropdownMenu>
					<Button onClick={() => setIsAddUserModalOpen(true)}>Vytvořit uživatele</Button>
				</div>
			</div>
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map(( headerGroup ) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(( header ) => {
									return (
										<TableHead key={header.id} className="whitespace-nowrap">
											{header.isPlaceholder
												? null
												: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map(( row ) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
								>
									{row.getVisibleCells().map(( cell ) => (
										<TableCell key={cell.id} className="py-2">
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									Žádný uživatel
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<div className="space-x-2">
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					>
						Předchozí
					</Button>
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					>
						Další
					</Button>
				</div>
			</div>
		</div>
	);
};
