import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUserState } from "@/hooks/user-state";
import { PartnerTable } from "@/components/PartnerTable";

const PartnerPage = () => {
	const { id } = useParams();
	const { isPartnerLoading, partner, fetchPartner } = useUserState();

	if(!id) {
		return null;
	}

	useEffect(() => {
		document.title = "Uživatel | JednotaCB";
		const userId = parseInt(id);
		fetchPartner(userId);
	}, []);

	if(isPartnerLoading) {
		return (
			<div className="flex justify-center items-center">
				Načítám data o partnerovi...
			</div>
		);
	}

	if(!partner && !isPartnerLoading) {
		return (
			<div className="flex justify-center items-center">
				Partner neexistuje.
			</div>
		);
	}

	return (
		<>
			<h2 className="text-center text-2xl mb-9">Přehled kupónů partnera <span className="text-primary">{partner?.partner}</span><br/>a&nbsp;dalších využívajících poskytovatele <span className="text-primary">{partner?.service_provider}</span></h2>

			<PartnerTable service_provider={parseInt(partner!.service_provider)} />
		</>
	);
};

export default PartnerPage;