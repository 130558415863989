import React, { useEffect } from "react";
import axios from "axios";

/* Pages */
import PageRouter from "@/pages/PageRouter";

/* Hooks */
import { useUserState } from "@/hooks/user-state";

/* Components */
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";

/* Styles */
import "@/styles/globals.css";

axios.defaults.baseURL = "/api/v1/";

const App = () => {
	const { toast } = useToast();
	const { setUserToast, isLoggedIn, fetchActiveUsername } = useUserState();


	useEffect(() => {
		if(toast) {
			setUserToast(toast);
		}
		if(isLoggedIn) {
			fetchActiveUsername();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageRouter />

			<Toaster />
		</>
	);
};

export default App;
