import React, { useEffect } from "react";

/* Types */
import { User } from "@/types";

/* Libs */
import { cn } from "@/lib/utils";

/* Icons */
import { ChevronDown, Undo2Icon } from "lucide-react";

/* Components */
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, } from "@/components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, } from "@/components/ui/table";
import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table";
import { useCouponState } from "@/hooks/coupon-state";
import { DatePickerWithRange } from "@/components/DatePickerWithRange";
import { DateRange } from "react-day-picker";
import { addMonths, format } from "date-fns";
import { cs } from "date-fns/locale";
import { Link } from "react-router-dom";

export const PartnerTable = ( { service_provider } : { service_provider : number } ) => {
	const { list, fetchCoupons, isCouponListLoading } = useCouponState();
	const [ date, setDate ] = React.useState<DateRange | undefined>({
		from : addMonths(new Date(), -1),
		to : new Date(),
	});
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const [ columnFilters, setColumnFilters ] = React.useState<ColumnFiltersState>(
		[]
	);
	const [ columnVisibility, setColumnVisibility ] =
		React.useState<VisibilityState>({});
	const [ rowSelection, setRowSelection ] = React.useState({});
	const columns : ColumnDef<User>[] = [
		{
			accessorKey : "customer_code",
			header : "Kód zákazníka (Partner)",
			cell : ( { row } ) => <div>{row.getValue("customer_code")}</div>,
		},
		{
			accessorKey : "name",
			header : "Název",
			cell : ( { row } ) => <div>{row.getValue("name")}</div>,
		},
		{
			accessorKey : "service_provider",
			header : "Poskytovatel služeb",
			cell : ( { row } ) => <div>{row.getValue("service_provider")}</div>,
		},
		{
			accessorKey : "created",
			header : "Datum ověření",
			cell : ( { row } ) => <div>{format(new Date(row.getValue("created")), "d. M. y H:mm", { locale : cs })}</div>,
		},
		{
			accessorKey : "is_used",
			header : "Použitý",
			cell : ( { row } ) => <div><Badge
				variant={row.getValue("is_used") ? "success" : "destructive"}>{row.getValue("is_used") ? "Ano" : "Ne"}</Badge></div>,
		},
		{
			accessorKey : "updated",
			header : "Datum použití",
			cell : ( { row } ) => <div>
				{row.getValue("is_used") ?
					<div>{format(new Date(row.getValue("updated")), "d. M. y H:mm", { locale : cs })}</div>
					:
					"–"
				}
			</div>,
		},
	];

	const table = useReactTable({
		data : list,
		columns,
		onSortingChange : setSorting,
		onColumnFiltersChange : setColumnFilters,
		onColumnVisibilityChange : setColumnVisibility,
		onRowSelectionChange : setRowSelection,
		getCoreRowModel : getCoreRowModel(),
		getPaginationRowModel : getPaginationRowModel(),
		getSortedRowModel : getSortedRowModel(),
		getFilteredRowModel : getFilteredRowModel(),
		state : {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
	});

	const storeColumnsVisibility = ( column : any, value : boolean ) => {
		const partnerColumnsVisibility = JSON.parse(localStorage.getItem("partnerColumnsVisibility") ?? "{}");
		partnerColumnsVisibility[column.columnDef?.accessorKey] = value;
		localStorage.setItem("partnerColumnsVisibility", JSON.stringify(partnerColumnsVisibility));
	};

	useEffect(() => {
		const partnerColumnsVisibility = JSON.parse(localStorage.getItem("partnerColumnsVisibility") ?? "{}");
		setColumnVisibility(partnerColumnsVisibility);
		Object.keys(partnerColumnsVisibility).forEach(( key ) => {
			const column = table.getColumn(key);
			if ( column ) {
				column.toggleVisibility(partnerColumnsVisibility[key]);
			}
		});
	}, []);

	useEffect(() => {
		if ( date?.from && date?.to ) {
			fetchCoupons(service_provider, format(date!.from!, "yyyy-MM-dd HH:mm:ss"), format(date!.to!, "yyyy-MM-dd HH:mm:ss"));
		}
	}, [ date ]);

	return (
		<div className={cn("w-full", isCouponListLoading ? "opacity-50" : "")}>
			<div className="py-4 flex-col gap-2 md:gap-0 md:flex-row flex justify-between items-center">
				<div className="flex gap-4 w-full items-center">
					<Button className="inline-flex items-center gap-2" variant="ghost" asChild>
						<Link to="/uzivatele">
							<Undo2Icon className="w-4 h-4" />
							Zpět na výpis uživatelů
						</Link>
					</Button>
				</div>
				<div className="flex justify-between md:justify-end w-full gap-4 items-center order-1 md:order-2">
					<DatePickerWithRange setDate={setDate} date={date} />
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button className="inline-flex items-center gap-2" variant="outline">
								Sloupce <ChevronDown className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							{table
								.getAllColumns()
								.filter(( column ) => column.getCanHide())
								.map(( column ) => {
									if ( column.columnDef.header !== "" ) {
										return (
											<DropdownMenuCheckboxItem
												key={column.id}
												className="capitalize"
												checked={column.getIsVisible()}
												onCheckedChange={( value ) => {
													storeColumnsVisibility(column, value);
													column.toggleVisibility(value);
												}}
											>
												{/*@ts-ignore*/}
												{typeof column?.columnDef?.header !== "function" ? column?.columnDef?.header : column.id}
											</DropdownMenuCheckboxItem>
										);
									}
								})}
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map(( headerGroup ) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map(( header ) => {
									return (
										<TableHead key={header.id} className="whitespace-nowrap">
											{header.isPlaceholder
												? null
												: flexRender(
													header.column.columnDef.header,
													header.getContext()
												)}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map(( row ) => (
								<TableRow
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
								>
									{row.getVisibleCells().map(( cell ) => (
										<TableCell key={cell.id} className="py-2 whitespace-nowrap">
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									Žádný kupón
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<div className="space-x-2">
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					>
						Předchozí
					</Button>
					<Button
						variant="outline"
						size="sm"
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					>
						Další
					</Button>
				</div>
			</div>
		</div>
	);
};
