import { create } from "zustand";
import axios from "axios";

interface useCouponStateStore {
	couponCode : string;
	couponId : string;
	couponFullName : string;
	couponMessage : string;
	couponInfo : string;
	list : any[];
	hasBlockCouponError : boolean;
	isCouponValid : boolean;
	isCouponBlocked : boolean;
	isCouponLoading : boolean;
	isCouponListLoading : boolean;
	setCouponMessage : ( couponMessage : string ) => void;
	setHasBlockCouponError : ( hasBlockCouponError : boolean ) => void;
	setCouponId : ( couponId : string ) => void;
	setCouponFullName : ( couponFullName : string ) => void;
	setIsCouponListLoading : ( isCouponLoading : boolean ) => void;
	setIsCouponLoading : ( isCouponLoading : boolean ) => void;
	setIsCouponBlocked : ( isCouponBlocked : boolean ) => void;
	setIsCouponValid : ( isCouponValid : boolean ) => void;
	setCouponCode : ( couponCode : string ) => void;
	validateCoupon : ( form : any, partner : string, service_provider : string ) => void;
	blockCouponCode : ( partner : string ) => void;
	resetForm : ( form : any ) => void;
	setCouponDefaultState : () => void;
	fetchCoupons : ( service_provider : number, dateFrom : string, dateTo : string ) => void;
}

export const useCouponState = create<useCouponStateStore>(( set ) => ( {
	couponCode : "",
	couponId : "",
	couponFullName : "",
	hasBlockCouponError : false,
	isCouponValid : false,
	isCouponListLoading : false,
	isCouponBlocked : false,
	isCouponLoading : false,
	couponMessage : "",
	couponInfo : "",
	list : [],
	setIsCouponListLoading : ( isCouponLoading : boolean ) => set(() => ( { isCouponListLoading : isCouponLoading } )),
	setCouponMessage : ( couponMessage : string ) => set(() => ( { couponMessage } )),
	setHasBlockCouponError : ( hasBlockCouponError : boolean ) => set(() => ( { hasBlockCouponError } )),
	setCouponId : ( couponId : string ) => set(() => ( { couponId } )),
	setCouponFullName : ( couponFullName : string ) => set(() => ( { couponFullName } )),
	setIsCouponLoading : ( isCouponLoading : boolean ) => set(() => ( { isCouponLoading } )),
	setIsCouponBlocked : ( isCouponBlocked : boolean ) => set(() => ( { isCouponBlocked } )),
	setIsCouponValid : ( isCouponValid : boolean ) => set(() => ( { isCouponValid } )),
	setCouponCode : ( couponCode : string ) => set(() => ( { couponCode } )),
	validateCoupon : ( form, partner, service_provider ) => {
		set(() => ( { isCouponLoading : true, couponCode : form.getValues().couponCode } ));
		axios.post("coupon/validate/", { code : useCouponState.getState().couponCode, partner, service_provider }).then(( response : any ) => {
			set(() => ( { isCouponLoading : false, couponMessage : response.data.message } ));
			if ( response.data.success ) {
				form.clearErrors();
				set(() => ( {
					isCouponValid : true,
					couponId : response.data.couponId,
					couponFullName : response.data.name,
					couponInfo : response.data.couponInfo
				} ));
			} else {
				set(() => ( { isCouponValid : false } ));
				if ( response.data.field ) {
					form.setError(response.data.field, {
						message : response.data.message
					});
				} else {
					set(() => ( {
						isCouponLoading : false,
						hasBlockCouponError : true,
						couponMessage : response.data.message
					} ));
				}
			}
		}).catch(( error ) => {
			console.error(error);
			set(() => ( {
				isCouponLoading : false,
				hasBlockCouponError : true,
				couponMessage : "Nepodařilo se ověřit kód slevového kupónu. (Server neodpovídá)"
			} ));
		});
	},
	blockCouponCode : ( partner : string ) => {
		set(() => ( { isCouponLoading : true, isCouponBlocked : false, hasBlockCouponError : false } ));
		axios.post("coupon/block/", {
			couponId : useCouponState.getState().couponId,
			partner,
		}).then(( response : any ) => {
			set(() => ( { isCouponLoading : false, couponMessage : response.data.message } ));
			if ( response.data.success ) {
				set(() => ( { isCouponBlocked : true } ));
			} else {
				set(() => ( { isCouponBlocked : false, hasBlockCouponError : true } ));
			}
		}).catch(( error ) => {
			console.error(error);
			set(() => ( {
				isCouponLoading : false,
				hasBlockCouponError : true,
				couponMessage : "Nepodařilo se uplatnit kód slevového kupónu. (Server neodpovídá)"
			} ));
		});
	},
	fetchCoupons : ( service_provider, dateFrom, dateTo ) => {
		set(() => ( { isCouponListLoading : true } ));
		axios.post("coupon/list", {
			service_provider,
			dateFrom,
			dateTo
		}).then(( response : any ) => {
			set(() => ( { list : response.data.list, isCouponListLoading : false } ));
		}).catch(( error ) => {
			set(() => ( { isCouponListLoading : false } ));
			console.error(error);
		});
	},
	resetForm : ( form ) => {
		form.clearErrors();
		form.reset();
		useCouponState.getState().setCouponDefaultState();
	},
	setCouponDefaultState : () => set(() => ( {
		couponId : "",
		couponFullName : "",
		couponCode : "",
		couponMessage : "",
		couponInfo : "",
		hasBlockCouponError : false,
		isCouponBlocked : false,
		isCouponLoading : false,
		isCouponValid : false,
	} ))
} ));