import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

/* Constants */
import { USER_ROLES } from "@/constants";

/* Hooks */
import { useUserState } from "@/hooks/user-state";

/* Pages */
import CouponVerificationPage from "@/pages/CouponVerificationPage";
import Layout from "@/pages/Layout";
import LoginPage from "@/pages/LoginPage";
import UserManagementPage from "@/pages/UserManagementPage";
import PartnerPage from "@/pages/PartnerPage";
import NotFoundPage from "@/pages/NotFoundPage";

const PageRouter = () => {
	const { isLoggedIn, role } = useUserState();
	const isAdmin = role === USER_ROLES.ADMIN;

	return (
		<Routes>
			<Route path="/" index element={isLoggedIn ? <Navigate to="/overeni" /> : <LoginPage />} />
			<Route element={<Layout />}>
				<Route path="/overeni" element={isLoggedIn ? <CouponVerificationPage /> : <Navigate to="/" />} />
				{isAdmin && (
					<>
						<Route path="/uzivatele" element={isLoggedIn && isAdmin ? <UserManagementPage /> : <Navigate to="/" />} />
						<Route path="/uzivatel/:id" element={isLoggedIn && isAdmin ? <PartnerPage /> : <Navigate to="/" />} />
					</>
				)}
			</Route>
			{!isLoggedIn && (
				<Route path="*" element={<Navigate to="/" />} />
			)}
			<Route path="*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default PageRouter;