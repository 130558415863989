"use client";

import * as React from "react";
import { format, addMonths, addDays } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { cs } from "date-fns/locale";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { SetStateAction, Dispatch } from "react";

export const DatePickerWithRange = ( {
	className,
	setDate,
	date
} : { className? : string,  setDate : Dispatch<SetStateAction<DateRange | undefined>>;   date : DateRange | undefined; } ) => {
	const [inputDate, setInputDate] = React.useState<DateRange | undefined>({
		from: date?.from,
		to: date?.to,
	})

	const calendarToggleChange = (isOpen:boolean) => {
		if(!isOpen) {
			setDate({
				from: inputDate?.from,
				to: addDays(new Date(inputDate!.to!), 1),
			});
		}
	}

	return (
		<div className={cn("grid gap-2", className)}>
			<Popover onOpenChange={calendarToggleChange}>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={"outline"}
						className={cn(
							"w-[12rem] sm:w-[14rem] justify-start flex items-center gap-2 text-left font-normal",
							!inputDate && "text-muted-foreground"
						)}
					>
						<CalendarIcon className="h-4 w-4" />
						{inputDate?.from ? (
							inputDate.to ? (
								<>
									{format(inputDate.from, "d. M. y", { locale : cs })} -{" "}
									{format(inputDate.to, "d. M. y", { locale : cs })}
								</>
							) : (
								format(inputDate.from, "d. M. y", { locale : cs })
							)
						) : (
							<span>Vyberte rozsah</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						initialFocus
						mode="range"
						locale={cs}
						defaultMonth={inputDate?.from}
						selected={inputDate}
						onSelect={setInputDate}
						numberOfMonths={1}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
};
