import { Outlet } from "react-router-dom";

/* Hooks */
import { useUserState } from "@/hooks/user-state";
import { useCouponState } from "@/hooks/coupon-state";

/* Components */
import { Logo } from "@/components/Logo";
import { cn } from "@/lib/utils";
import { UserNav } from "@/components/UserNav";

const Layout = () => {
	const { isUserLoading } = useUserState();
	const { isCouponLoading } = useCouponState();

	return (
		<div className="container space-y-6">
			<div className={cn("py-4 border-b flex justify-between items-center",
				isUserLoading ? "animate-pulse pointer-events-none" : "",
				isCouponLoading ? "animate-pulse pointer-events-none" : ""
			)}>
				<Logo className="w-32 h-12" />
				<UserNav />
			</div>

			<Outlet />

		</div>
	);
};

export default Layout;