import { useEffect } from "react";

/* Components */
import { LoginForm } from "@/components/forms/login/LoginForm";
import { Logo } from "@/components/Logo";

const LoginPage = () => {

	useEffect(() => {
		document.title = "Přihlášení | JednotaCB";
	}, []);

	return (
		<div className="container py-4 space-y-6 flex flex-col lg:h-full lg:justify-center items-center">
			<Logo className="block mb-4 lg:mb-10 w-32 lg:w-64 h-12 lg:h-24" />
			<LoginForm />
		</div>
	);
};

export default LoginPage;