import { Link } from "react-router-dom";

/* Libs */
import { cn } from "@/lib/utils";

interface LogoProps {
	className? : string;
}

export const Logo = ( { className = "" } : LogoProps ) => {
	return (
		<div className={cn("transition-colors hover:bg-primary/80 bg-primary p-1 rounded-md", className)}>
			<Link to="/">
				<img src="/assets/images/jednota-logo.png" className="h-full w-full object-contain" alt="Jednota" />
			</Link>
		</div>
	);
};