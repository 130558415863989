import { useEffect } from "react";

/* Hooks */
import { useCouponState } from "@/hooks/coupon-state";

/* Components */
import { CouponVerificationForm } from "@/components/forms/coupon-verification/CouponVerificationForm";

const CouponVerificationPage = () => {
	const {setCouponDefaultState} = useCouponState();

	useEffect(() => {
		document.title = "Ověření | JednotaCB";

		return () => {
			setCouponDefaultState();
		}
	}, []);

	return (
		<>
			<CouponVerificationForm />
		</>
	);
};

export default CouponVerificationPage;