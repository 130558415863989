import { Link, useLocation } from "react-router-dom";

/* Components */
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuItem
} from "@/components/ui/dropdown-menu";
import { MenuIcon } from "lucide-react";

/* Constants */
import { USER_ROLES } from "@/constants";

/* Hooks */
import { useUserState } from "@/hooks/user-state";

export const UserNav = () => {
	const location = useLocation();
	const { role, logout, username } = useUserState();

	const logoutUser = ( event : any ) => {
		event.preventDefault();

		logout();
	};

	const getUsernameFirstLetter = () => {
		return username.charAt(0).toUpperCase();
	};

	const getCapitalizedUsername = () => {
		return username.charAt(0).toUpperCase() + username.slice(1);
	};

	const getActiveClassNames = (path:string) => {
		return location.pathname === path ? "bg-accent pointer-events-none" : "";
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Avatar>
					<AvatarFallback>
						{username ? getUsernameFirstLetter() : <MenuIcon />}
					</AvatarFallback>
				</Avatar>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="min-w-[10rem]">
				<DropdownMenuLabel>{username ? getCapitalizedUsername() : "Účet"}</DropdownMenuLabel>
				<DropdownMenuSeparator />
				<DropdownMenuItem asChild className={getActiveClassNames("/overeni")}>
					<Link to="/overeni">Ověření kupónu</Link>
				</DropdownMenuItem>
				{role === USER_ROLES.ADMIN && (
					<DropdownMenuItem asChild className={getActiveClassNames("/uzivatele")}>
						<Link to="/uzivatele">Správa uživatelů</Link>
					</DropdownMenuItem>
				)}
				<DropdownMenuItem onClick={( event ) => logoutUser(event)}>
					Odhlásit se
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};