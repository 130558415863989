import { useEffect } from "react";
import { Logo } from "@/components/Logo";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

const NotFoundPage = () => {
	useEffect(() => {
		document.title = "Přihlášení | JednotaCB";
	}, []);

	return (
		<div className="container py-4 space-y-6 flex flex-col lg:h-full lg:justify-center items-center">
			<Logo className="block mb-4 lg:mb-10 w-32 lg:w-64 h-12 lg:h-24" />
			<h1 className="text-2xl">Stránka nebyla nalezena.</h1>
			<p>
				<Button variant="link" className="text-lg" asChild>
					<Link to="/">Vrátit se na hlavní stránku</Link>
				</Button>
			</p>
		</div>
	);
};

export default NotFoundPage;